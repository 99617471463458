<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="图片" min-width="100" align="center">
        <template slot-scope="scope">
          <el-image
            style="height: 80px"
            :preview-src-list="[scope.row.cover]"
            :src="scope.row.cover"
            class="banner-image"
            lazy
          />
        </template>
      </el-table-column> -->
      <el-table-column label="题库分类" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_weight }}
        </template>
      </el-table-column> -->
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <!-- slot-scope="scope" -->
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="goSubject(0, scope.row.id)"
          >
            顺序答题
          </el-button>
          <el-button
            size="mini"
            type="success"
            @click="goSubject(1, scope.row.id)"
          >
            随机答题
          </el-button>
          <el-button
            size="mini"
            type="warning"
            @click="goSubject(2, scope.row.id)"
          >
            答题记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import UploadOne from "@/components/UploadOne/index.vue";
import Tinymce from "@/components/Tinymce";
export default {
  components: {
    UploadOne,
    Tinymce,
  },
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/teacherQuestionBank/typeList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    goSubject(index, id) {
      if (index == 0) {
        this.$router.push({
          path: "/question/subject",
          query: {
            teacher_question_bank_type_id: id,
          },
        });
      } else if (index == 1) {
        this.$router.push(
          "/question/subject?teacher_question_bank_type_id=" + id
        );
      } else {
        this.$router.push("/question/record");
      }
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style>
.el-image.banner-image img {
  width: auto;
}
</style>
